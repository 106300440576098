<template>
    <div class="underwriting h-font-lg" id="underwriting">
        <div class="underwriting-title">
            <span>核保结果</span><van-icon v-if="['3', '2'].includes(formData.basis.insuredStatus)" @click="refreshStatus" :name="require('./image/refresh.png')" />
        </div>
        <div class="base h-group car-des">
            <div class="h-cell h-cell--center">
                <div class="h-cell__title" style="font-size:20px">{{ plateNoName }}</div>
                <div class="h-cell__value">
                    <van-button v-if="formData.compulsory.insuredStatus !== '7'" type="primary" icon="edit" size="mini" @click="toRisk">调整方案</van-button>
                </div>
            </div>
            <div class="h-cell__content">
                <div class="warn">
                <i class="van-icon van-icon-warning" /> {{ riskDesc }}
                </div>
            </div>
        </div>
        <div v-if="JSON.stringify(formData.compulsory) !== '{}'" class="compulsory h-group">
            <div class="h-cell h-cell--center">
                <div class="h-cell__title">
                    <van-col span="8">
                      <span class="icon" style="background-color:#57ccc2">强</span>交强险
                    </van-col>
                    <van-col span="16">
                      <span v-if="formData.compulsory">{{ formData.compulsory.policyCode }}</span>
                    </van-col>
                </div>
            </div>
            <div class="h-cell__content">
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">核保状态</van-col>
                    <van-col span="18">{{ insuredStatusCompare[formData.compulsory.insuredStatus] }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6" class="h-text-color_gray-6">投保单号</van-col>
                    <van-col span="18">{{formData.compulsory.applyCode}}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6" class="h-text-color_gray-6">备注</van-col>
                    <!-- <van-col span="18" class="insured-msg" id="insuredMsg">备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注</van-col> -->
                    <van-col span="18" class="insured-msg" id="insuredMsg">{{formData.compulsory.insuredMsg}}</van-col>
                </van-row>
            </div>
        </div>

        <div v-if="JSON.stringify(formData.commercial) !== '{}'" class="commercial h-group">
            <div class="h-cell h-cell--center">
                <div class="h-cell__title">
                    <van-col span="8">
                        <span class="icon" style="background-color:#ff976a">商</span>商业险
                    </van-col>
                    <van-col span="16">
                        <span v-if="formData.commercial">{{ formData.commercial.policyCode }}</span>
                    </van-col>
                </div>
            </div>
            <div class="h-cell__content">
                <van-row>
                    <van-col span="6"  class="h-text-color_gray-6">核保状态</van-col>
                    <van-col span="18">{{ insuredStatusCompare[formData.commercial.insuredStatus] }}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6" class="h-text-color_gray-6">投保单号</van-col>
                    <van-col span="18">{{formData.commercial.applyCode}}</van-col>
                </van-row>
                <van-row>
                    <van-col span="6" class="h-text-color_gray-6">备注</van-col>
                    <van-col span="18" class="insured-msg">{{formData.commercial.insuredMsg}}</van-col>
                </van-row>    
            </div>
        </div>
        <TermManage ref="termManage" v-if="formData.basis.insuredOrgCode !== 'TPIC'" :formData="formData" />
        <div class="quote-result_bnt">
            <div v-if="formData.basis.insuredStatus === '1'" class="quote-result_bnt--fixed">
                <van-button type="primary" block round @click="back">返回</van-button>
            </div>
            <div v-else class="quote-result_bnt--fixed success">
                <div v-if="formData.basis.insuredOrgCode === 'YGBX'">
                    <van-button type="primary" size="small" @click="toPath('Pay')" v-if="permBtns.includes('C608') && formData.basis.insuredStatus === '2'">支&nbsp;&nbsp;付</van-button>
                    <van-button type="primary" size="small" @click="collection" v-if="((formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2') || (formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2') || (formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2')) && (formData.holder.custType === '0' || formData.insured.custType === '0')">身份证采集</van-button>
                    <van-button type="primary" size="small" @click="toPath('UpdateCode')" v-if="permBtns.includes('C607') && formData.basis.mobile && ((formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2') || (formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2') || (formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2')) && (formData.holder.custType === '0' || formData.insured.custType === '0')">验证码更新</van-button>
                    <van-button type="primary" size="small" @click="toPath('SendCode')" v-if="permBtns.includes('C606') && formData.basis.mobile && ((formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2') || (formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2') || (formData.basis.insuredArea === '110100' && formData.basis.insuredStatus === '2')) && (formData.holder.custType === '0' || formData.insured.custType === '0')">发送验证码</van-button>
                    <van-button type="primary" size="small" @click="toPath('UploadFile')" v-if="formData.basis.insuredOrgCode && ['0', '2', '3'].includes(formData.basis.insuredStatus)">上传资料</van-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { request } from "@/api/service";
import dayjs from 'dayjs'
import { Toast } from 'vant'
import common from '../common/mixin/common.js'
import TermManage from '@/views/miniprogram/quote/list/termManage/index.vue'
export default {
  name: 'Underwriting',
  mixins: [common],
  components: { TermManage },
  data() {
    return {
        insuredStatusCompare: {
            '0': '报价成功',
            '1': '报价失败',
            '2': '待支付',
            '3': '人工核保',
            '4': '核保失败',
            '5': '支付成功',
            '6': '支付失败',
            '7': '承保成功'
        },
        formData: {
            basis: {},
            commercial: {},
            compulsory: {}
        },
        permBtns: [],
        subBusinessNo: null
    }
  },
  computed: {
    ...mapState("car", ["quote","quoteRes"]),
    ...mapState("user", ["info"]),
    plateNoName(){
      if(!(this.quote&&this.quote.vehicle)){
        return ''
      }
      if(!this.quote.vehicle.plateNo){
        return '新车未上牌'
      }
      return this.quote.vehicle.plateNo
    },
    riskDesc(){
      if(!this.quote){
        return ''
      }
      let compulsoryFlag=false
      let compulsoryNum = 0
      if(this.quote.compulsory){
        compulsoryFlag = true
        compulsoryNum = 1
      }
      let commercialFlag=false
      let commercialNum = 0
      let commercialStr=""
      if(this.quote.commercial && this.quote.commercial.riskList){
        commercialFlag = true
        const commercialList= this.quote.commercial.riskList && this.quote.commercial.riskList.map(item=>item.riskName)
        commercialNum = commercialList && commercialList.length
        commercialStr=`商业险(${commercialList && commercialList.join('、')})`
      }
      return `已选择投保${compulsoryFlag?'交强险':''}${compulsoryFlag&&commercialFlag?'、':''}${commercialFlag?commercialStr:''}，共${compulsoryNum+commercialNum}项`
    },
  },
  async created() {
  },
  mounted() {
    underwriting.style.height = (document.documentElement.clientHeight) + 'px'
    this.subBusinessNo = this.$route.query.id
    this.getDetail(this.subBusinessNo, 'underwriting')
    // this.$nextTick(() => {
    //     test.addEventListener('click', function (e) {
    //         // e.stopPropagation()
    //         console.log(e.target)
    //     })
    // })
  },
  methods: {
    dayjs,
    back () {
        this.$router.go(-1)
    },
    refreshStatus () {
        let setInter = setInterval(() => {
            Toast.loading({
            forbidClick: true
            }, 500)
        })
        request({
          url: '/afis-api-manage/invoke/core/auto/updateAutoPolicyStatusIn',
          method: 'post',
          data: {
            subBusinessNo: this.subBusinessNo
          }
        }).then(res => {
            this.$set(this.quoteRes, res.basis.insuredOrgCode, res)
            this.$store.dispatch("car/setQuoteRes", this.quoteRes)
            // this.formData = res
            // if (!this.formData.commercial) {
            //     this.formData.commercial = {}
            // }
            // if (!this.formData.compulsory) {
            //     this.formData.compulsory = {}
            // }
            this.getDetail(this.subBusinessNo, 'underwriting')
            clearInterval(setInter)
            Toast.clear()
        }).catch(e => {
            clearInterval(setInter)
            Toast.clear()
        })
    },
    underwritingIn () {
        request({
          url: '/afis-api-manage/invoke/core/auto/underwritingIn',
          method: 'post',
          data: {
            subBusinessNo: this.subBusinessNo
          }
        }).then(res => {
            let info = this.quoteRes[res.basis.insuredOrgCode]
            for (let i in res) {
                info[i] = res[i]
            }
            this.$set(this.quoteRes, res.basis.insuredOrgCode, info)
            this.$store.dispatch("car/setQuoteRes", this.quoteRes)
            this.formData = this.quoteRes[res.basis.insuredOrgCode]
            if (!this.formData.commercial) {
                this.formData.commercial = {}
            }
            if (!this.formData.compulsory) {
                this.formData.compulsory = {}
            }
            Toast.clear()
        }).catch(e => {
            Toast.clear()
        })
    },
    toRisk(){
      this.$router.push({ path: "/miniprogram/quote/normal/base" });
    },
    collection () {
      this.$store.dispatch("car/setHolderInfo", this.quote.holder)
      this.$store.dispatch("car/setInsuredInfo", this.quote.insured)
      this.$router.push({
        name: 'CardCollection'
      })
    },
    // 初始化按钮
    async initBtn(insuredOrgCode) {
      const { info } = this.$store.getters
      let dealerId = null
      let orgCode = null
      if (info.busiType === '2') {
        dealerId = info.orgId
        orgCode = info.issueOrgId
      }
      if (info.busiType === '1') {
        dealerId = this.formData.basis.dealerId
        orgCode = this.formData.basis.orgId
      }
      const permBtns = await request({
        url: '/afis-auto-web/auto/config/channel/queryQuoteBtnList',
        method: 'post',
        data: {
          commonApiConfigType: '1',
          orgCode,
          insuredOrgCode,
          businessChannelId: dealerId
        }
      })
      this.permBtns = permBtns || []
    },
    toPath (name) {
      this.$router.push({
        name: name
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.underwriting {
    background: url('./image/bg-title.png') no-repeat fixed;
    background-size: 100% 100%;
    padding: 0 8px;
    overflow: auto;
    .underwriting-title {
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        padding: 14px 0 20px 0px;
        .van-icon {
            font-weight: bold;
            margin-left: 6px;
            position: relative;
            top: 2px;
        }
    }
    .h-cell__content{
        padding: 6px 8px;
        font-size: $font-size-sm;
        ::v-deep .van-row {
            padding:9px 0;
        }
    }
    .h-cell__title{
        font-weight: $font-weight-bold-lg;
        font-size: $font-size-md;
        span.icon {
            border-radius: $border-radius-sm;
            color: $white;
            padding: 3px;
            margin-right:6px;
        }
    }
    .h-cell{
        padding: 10px 8px;
    }
    .h-cell__value {
        color: #0066FF;
        font-size: 12px;
    }
    .base{
        .warn{
            text-indent: -4px;
            padding-left: 12px;
            font-size: $font-size-xs;
            color: $primary-color;
        }
    }
    .h-group{
        margin-bottom: 10px;
        padding: 8px;
        border-radius: $border-radius-lg;
    }
    .car-des {
        .refresh-des {
            margin-right: 4px;
        }
        .van-icon {
            font-size: 14px;
        }
    }
    .insured-msg {
        // text-overflow: -o-ellipsis-lastline;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // line-clamp: 3;
        // -webkit-box-orient: vertical;
    }
    .quote-result_bnt {
        height: 60px;
        .success {
            text-align: center;
            button {
                min-width: 50px;
                margin-right: 10px;
            }
        }
    }
    .quote-result_bnt--fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background-color: #fff;
        padding: 10px;
        border-top: #f7f8fa solid 2px;
    }
}
</style>
